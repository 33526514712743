import React, { useRef, useState } from 'react'

import { AiOutlineClose } from "react-icons/ai";
import { FaSpinner } from "react-icons/fa";

import emailjs from 'emailjs-com';
import { toast } from 'react-toastify';


export const ModalForm = ({ open, close}) => {

    const form = useRef()

    const [loading, setLoading] = useState(false);
    const [nome, setNome] = useState('');
    const [telefone, setTelefone] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    async function handleSendEmail(e){
        e.preventDefault();

        if(nome === '' || telefone === '' || email === '' || message === ''){
            toast.error('Algum campo não foi preenchido.');
            return;
        }

        setLoading(true)

        await emailjs.sendForm(
          process.env.REACT_APP_SERVICE_ID, 
          process.env.REACT_APP_TEMPLATE_ID,
          form.current,
          process.env.REACT_APP_USER_ID
        ).then((response) => {
            console.log(response)
            toast.success('E-mail enviado com sucesso.');
        }).catch((err) => {
            console.log(err)
            toast.success('Ocorreu algum erro.');
        })

        setLoading(false)

        setNome('')
        setTelefone('')
        setEmail('')
        setMessage('')

        close();
    }
    
    return (
        <>
            { open &&
                <div className="fixed z-50 mt-6 flex justify-center items-center w-full inset-0 h-[calc(100%-1rem)] max-h-full">
                    <div className="relative w-full p-4 max-w-7xl max-h-full flex justify-center items-center m-auto">

                        <div className="relative bg-white rounded-lg shadow-2xl w-[500px] m-[10%]-auto h-[500px] overflow-y-auto overflow-x-hidden py-4">

                            <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t">
                                <h3 className="text-lg font-semibold text-gray-900 font-karla">
                                    Solicitar Cotação
                                </h3>
                                <AiOutlineClose height={25} width={25} className='cursor-pointer' onClick={close} />
                            </div>

                            <form ref={form} onSubmit={(e) => handleSendEmail(e)} className='flex flex-col space-y-3 px-5 mt-3'>
                                <label className='font-bold font-karla text-lg'>Nome</label>
                                <input value={nome} onChange={(e) => setNome(e.target.value)} type='text' name='nome' className='border px-3 py-2' placeholder='Digite seu nome' />

                                <label className='font-bold font-karla text-lg'>Número de telefone</label>
                                <input value={telefone} onChange={(e) => setTelefone(e.target.value)} type='text' name='telefone' className='border px-3 py-2' placeholder='Digite seu telefone' />

                                <label className='font-bold font-karla text-lg'>E-mail</label>
                                <input value={email} onChange={(e) => setEmail(e.target.value)} type='text' name='email' className='border px-3 py-2' placeholder='Digite seu e-mail' />

                                <label className='font-bold font-karla text-lg'>Como podemos ajudar ?</label>
                                <textarea value={message} onChange={(e) => setMessage(e.target.value)} type='text' name='message' className='border px-3 py-2 resize-none h-[160px]' placeholder='Como podemos ajudar'></textarea>

                                <button type='submit' className='py-2 px-2 bg-blue-500 cursor-pointer hover:bg-blue-600 text-white font-karla text-lg flex justify-center items-center'>
                                    {
                                        loading ? ( <FaSpinner height={25} width={25} className='animate-spin' />  ) : ( <p>Enviar</p> )
                                    }
                                    
                                    
                                </button>
                            </form>

                        </div>
                    </div>
                </div>
            }
        </>
    )
}
