
import React from 'react'

import { AiFillStar } from "react-icons/ai";

export const Depositions = ({deposition}) => {
  return (
    <div className='md:w-[300px] w-full space-y-5 border-b solid border-gray-400 py-6 md:border-none md:py-0'>
        <div className='flex items-center justify-between'>
            <div className='flex space-x-1'>
                <AiFillStar size={20} className='text-yellow-400' />
                <AiFillStar size={20} className='text-yellow-400' />
                <AiFillStar size={20} className='text-yellow-400' />
                <AiFillStar size={20} className='text-yellow-400' />
                <AiFillStar size={20} className='text-yellow-400' />
            </div>
            
            <div>
                <p className='font-karla font-normal text-gray-400'>
                    {deposition.time}
                </p>
            </div>
            
        </div>
        <p className='font-karla font-normal'>
            "{deposition.deposition}"
        </p>

        <p className='text-[#d07ea7] font-lora font-bold'>
            - {deposition.autor}
        </p>
    </div>

  )
}
