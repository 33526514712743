import './App.css';

import bgImage from './assets/bg-image.jpg'

import { Depositions } from './components/Depositions';
import { useState } from 'react';

import Iframe from 'react-iframe'
import { AiFillFileText } from "react-icons/ai";
import { AiFillCalendar } from "react-icons/ai";
import { AiFillPhone } from "react-icons/ai";
import { AiOutlineMenu } from "react-icons/ai";
import { AiOutlineClose } from "react-icons/ai";
import { FaFacebook } from "react-icons/fa";
import { FaInstagramSquare } from "react-icons/fa";

import logo from './assets/logo-rosa.jpg'
import galary1 from './assets/galary-1.jpg'
import galary2 from './assets/galary-2.jpg'
import galary3 from './assets/galary-3.jpg'
import galary4 from './assets/galary-4.jpg'
import galary5 from './assets/galary-5.jpg'
import galary6 from './assets/galary-6.jpg'
import galary7 from './assets/galary-7.jpg'
import galary8 from './assets/galary-8.jpg'
import galary9 from './assets/galary-9.jpg'
import { MenuMobile } from './components/MenuMobile';
import { ModalForm } from './components/ModalForm';


function App() {


  const [openModal, setOpenModal] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [depositions, setDepositions] = useState(
    [
      {
        id: '1',
        deposition: "Meu pai ficou Durante 2anos e 8meses , foi maravilhoso muitíssimo bem cuidado. Só tenho agradecer a família rosa de saron. O carinho e a responsabilidade deles é demais. Super recomendo.",
        time: '4 meses atrás',
        autor: 'Magali V'
      },

      {
        id: '2',
        deposition: "Recomendo,sei de histórias lindas da Clínica, profissionais de coração de ouro.Todas as pessoas se tratam como se fossem família,e e realmente uma grande família a começar da dona da Clínica Geni Ramos ,pessoa bonissima,amorosa, comprometida com os seus pacientes, cuidado médico , carinho, atividades,as ajudantes tbem todas escolhidas a dedo pra cuidar dos amigos pacientes.",
        time: '4 meses atrás',
        autor: 'Elisa S'
      },

      {
        id: '3',
        deposition: "Meu avô mora neste Lar há quase 10 anos e desde sempre o carinho, cuidado e atenção especial são valores que fazem dessa casa um lugar tão especial para ele. Obrigada por tudo Geni e equipe! Vocês são anjos na vida dessas pessoas!",
        time: '4 meses atrás',
        autor: 'Rosana V'
      }
    ]
  )

  return (
    <>
    <header className='w-screen'>
        <div className="flex fixed bg-white w-full px-3 md:px-20 py-4 h-16 shadow-lg">
            <div className="container flex items-center mx-auto justify-between">
              
              <div className='flex items-center space-x-5'>
                {
                  openMenu ? <AiOutlineClose size={30} color='#d07ea7' className='cursor-pointer' onClick={() => setOpenMenu(!openMenu)} />
                  : <AiOutlineMenu size={30} color='#d07ea7' className='cursor-pointer' onClick={() => setOpenMenu(!openMenu)} />
                }
                <p className='text-[#d07ea7] cursor-pointer font-bold '>
                  {/*
                  <a href='#' className='font-lora text-[22px]'>Casa de Repouso Rosa de Saron</a>*/}
                  <a>
                    <img src={logo} width={50} height={50} />
                  </a>
                </p>
              </div>

                <div className="items-end text-white font-bold md:flex ">
                  <nav className='flex-1'>
                    <ul className='flex justify-end flex-1'>
                      <li className='text-[#d07ea7] hover:text-[#696969] cursor-pointer px-4' onClick={() => setOpenModal(!openModal)}>
                        <span className='text-nowrap text-lg leading-4 font-karla font-normal flex items-center space-x-1'>
                          <AiFillFileText />
                          <p>Solicitar Cotação</p>
                        </span>
                      </li>
                      <li className='text-[#d07ea7] hover:text-[#696969] cursor-pointer px-4'>
                        <a href='#' className='text-nowrap text-lg leading-4 font-karla font-normal flex items-center space-x-1'>
                          <AiFillCalendar />
                          <p>Agendar Horario</p>
                        </a>
                      </li>
                      <li className='text-[#d07ea7] hover:text-[#696969] cursor-pointer px-4'>
                        <a href='https://api.whatsapp.com/send?phone=5515981003459' target='_blank' className='text-nowrap text-lg leading-4 font-karla font-normal flex items-center space-x-1'>
                          <AiFillPhone />
                          <p>Ligar Agora</p>
                        </a>
                      </li>
                      
                    </ul>
                  </nav>
                </div>
            </div>
        </div>
    </header>


    <main className='mx-auto max-w-7xl pt-40 md:w-full w-[100vh] flex justify-center items-center flex-col'>
      <div className='flex flex-col text-center items-center space-y-7'>
        <h1 className='text-[#d07ea7] text-[56px] font-bold font-lora leading-[3.5rem]'>
          Rosa de Saron
        </h1>

        <p className='font-karla font-normal text-[#202124]'>
          Casa De Repouso Para Idosos <br /> Aberto 24 horas
        </p>

        <button className='bg-[#d07ea7] hover:bg-[#696969] rounded-sm w-56 px-3 py-5 text-center' onClick={() => setOpenModal(!openModal)}>
          <p className='font-karla font-normal text-white uppercase'>
           Solicitar Cotação
          </p>
        </button>
      </div>

      <div className='mt-10 pb-16'>
        <img src={bgImage} width='100%' height='auto' />
      </div>
      
      <div className='pb-28 flex flex-col items-center w-full' id="depositions">
        <hr className='border solid border-black w-14  text-center' />
        <p className='font-lora font-bold text-base mt-3'>
          <strong>
            DEPOIMENTOS
          </strong>
        </p>

        <div className='flex md:flex-row flex-col items-center md:justify-around w-full px-14 mt-6 space-y-8'>
          {
            depositions.map((deposition) => (
              <Depositions key={deposition.id} deposition={deposition} />
            ))
          }
        </div>

        <ul className='mt-10 flex space-x-7'>
          <li className='space-y-2'>
            <a href='https://www.google.com/search?hl=pt-BR&gl=br&q=Rosa+de+Saron+-+Av.+Gon%C3%A7alves+Magalh%C3%A3es,+650+-+Vila+Trujillo,+Sorocaba+-+SP,+18060-240&ludocid=17836177991608279615&lsig=AB86z5Wyh5xpcij_90eF55qCn4kU#lrd=0x94c58b2427bf04e7:0xf786d5621c73a23f,3' target='_blank' className='uppercase text-[#d07ea7] font-extrabold'>
              Escreva um comentário
            </a>
            <hr className='border solid border-[#d07ea7]' />
          </li>

          <li className='space-y-2'>
            <a href='https://www.google.com/search?hl=pt-BR&gl=br&q=Rosa+de+Saron+-+Av.+Gon%C3%A7alves+Magalh%C3%A3es,+650+-+Vila+Trujillo,+Sorocaba+-+SP,+18060-240&ludocid=17836177991608279615&lsig=AB86z5Wyh5xpcij_90eF55qCn4kU#lrd=0x94c58b2427bf04e7:0xf786d5621c73a23f,1' target='_blank'  className='uppercase text-[#d07ea7] font-extrabold'>
              saiba mais
            </a>
            <hr className='border solid border-[#d07ea7]' />
          </li>
        </ul>


      </div>

      <div id='quemSomos'>
      <div className='w-full pb-[150px] mb-[100px] bg-[#f6f6f6] mx-auto m-12 space-x-8 py-16'>

          <div className='flex flex-col items-center justify-center w-full mt-20'>

            <div className='flex flex-col items-center justify-center space-y-5'>
              <hr className='border solid border-black w-14  text-center' />
              <p className='font-lora font-bold text-base mt-3'>
                <strong>
                  CARINHO, RESPEITO E DIGNIDADE
                </strong>
              </p>

              <p className='italic font-bold text-[#202124]'>
              CASA DE REPOUSO PARA IDOSOS ROSA DE SARON 
              </p>
            </div>

            <div className='w-[64%] mt-10 leading-10 space-y-5'>
              <p className='font-karla font-semibold italic text-gray-500 '>
                Proporcionamos ambientes adaptados dentro das normas de segurança preconizadas para assistência ao idoso.
              </p>

              <p className='font-karla font-semibold italic text-gray-500 '>

              </p>

              <p className='font-karla font-semibold italic text-gray-500 '>
                Através da proposta individualizada, as expectativas são abordadas, sendo oferecido auxílio para realização das atividades de vida diária, sempre que necessário.
              </p>

              <p className='font-karla font-semibold italic text-gray-500'>
                Através deste site você pode conhecer nossas instalações e nossa proposta de trabalho.
              </p>

              <p className='font-karla font-semibold italic text-gray-500 '>
                Agradecemos sua visita.
              </p>
            </div>
          </div>

      </div>
      </div>


      <div className='pb-28 flex flex-col items-center' id='galeria'>
        <hr className='border solid border-black w-14  text-center' />
        <p className='font-lora font-bold text-base mt-3'>
          <strong>
            GALERIA
          </strong>
        </p>

        <div className='grid grid-cols-1 md:grid-cols-3 gap-8 mt-8 w-full px-0 md:px-20'>

          <div>
            <img src={galary1} width={450} height={450} className='hover:scale-110 duration-300' />
          </div>

          <div>
            <img src={galary2} width={450} height={450} className='hover:scale-110 duration-300' />
          </div>

          <div>
            <img src={galary3} width={450} height={450} className='hover:scale-105 duration-300' />
          </div>

          <div>
            <img src={galary4} width={450} height={450} className='hover:scale-105 duration-300' />
          </div>

          <div>
            <img src={galary5} width={450} height={450} className='hover:scale-105 duration-300' />
          </div>

          <div>
            <img src={galary6} width={450} height={450} className='hover:scale-105 duration-300' />
          </div>

          <div>
            <img src={galary7} width={450} height={450} className='hover:scale-105 duration-300' />
          </div>

          <div>
            <img src={galary8} width={450} height={450} className='hover:scale-105 duration-300' />
          </div>

          <div>
            <img src={galary9} width={450} height={450} className='hover:scale-105 duration-300' />
          </div>

        </div>
      </div>

      <div className='pb-28 flex flex-col items-center w-full md:px-20 px-0' id='contato'>
        <hr className='border solid border-black w-14  text-center' />
        <p className='font-lora font-bold text-base mt-3'>
          <strong>
          ENTRAR EM CONTATO
          </strong>
        </p>

        <Iframe url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3659.009397605578!2d-47.470676499999996!3d-23.496171!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94c58b2427bf04e7%3A0xf786d5621c73a23f!2sRosa%20de%20Saron!5e0!3m2!1spt-BR!2sbr!4v1705794509337!5m2!1spt-BR!2sbr"
          width="100%"
          height="500px"
          id=""
          className="mt-10"
          display="block"
          />

      </div>

      <div className='pb-28 w-full md:px-28 px-10'>
        <ul className='flex flex-col md:justify-between md:flex-row'>
          <li className='space-y-5 flex-1'>
            <h1 className='font-karla font-bold text-xl mb-6'>
              Contato
            </h1>
            <a href='https://api.whatsapp.com/send?phone=5515981003459' target='_blank' className='font-karla font-normal uppercase text-white'>
              <button className='bg-[#d07ea7] hover:bg-[#696969] rounded-sm w-56 px-3 py-5 text-center'>
                  Ligar Agora
              </button>
            </a>

            <p className='font-karla'>(15) 98100-3459</p>

          </li>
          <li className='space-y-5 flex-1'>
            <h1 className='font-karla font-bold text-xl mb-6'>
              Endereço
            </h1>
            <a target='_blank' href='https://www.google.com/maps/dir//Casa+de+Repouso+Rosa+de+Saron/data=!4m8!4m7!1m0!1m5!1m1!1s0x94c58b2427bf04e7:0xf786d5621c73a23f!2m2!1d-47.470676499999996!2d-23.496171' 
              className='font-karla font-normal uppercase text-white'>
              <button className='bg-[#d07ea7] hover:bg-[#696969] rounded-sm w-56 px-3 py-5 text-center'>
                  Ver Rotas
              </button>
            </a>

            <p className='font-karla leading-8'>Avenida Gonçalves Magalhães, <br /> 650 - Vila Trujillo
                      Sorocaba <br /> - SP
                      18060-240
                      Brasil</p>
          </li>
          <li className='space-y-5 flex-1'>
            <h1 className='font-karla font-bold text-xl'>
              Horário de Funcionamento
            </h1>
            <ul className='space-y-2'>
              <li>
                <p className='font-karla'>seg.:	Atendimento 24 horas</p>
              </li>
              <li>
                <p className='font-karla'>ter.:	Atendimento 24 horas</p>
              </li>
              <li>
                <p className='font-karla'>qua.:	Atendimento 24 horas</p>
              </li>
              <li>
                <p className='font-karla'>quin.:	Atendimento 24 horas</p>
              </li>
              <li>
                <p className='font-karla'>sex.:	Atendimento 24 horas</p>
              </li>
              <li>
                <p className='font-karla'>sab.:	Atendimento 24 horas</p>
              </li>
              <li>
                <p className='font-karla'>dom.:	Atendimento 24 horas</p>
              </li>
            </ul>
          </li>
        </ul>
      </div>

      <footer className='flex flex-col items-center justify-center my-12 border-t solid border-gray-400 py-6 w-full'>
        <img src={logo} height={150} width={150} />
        <p className='text-base font-karla'>&copy; clinica rosa de saron todos os direitos reservados.</p>
        <div className='flex space-x-4 mt-4'>
          <a href='https://www.facebook.com/casaderepousorosadesaron?mibextid=ZbWKwL' target='_blank'>
            <FaFacebook size={35} />
          </a>

          <a href='https://www.instagram.com/casaderepousorosadesaron?igsh=MWxnY2pubnYzZWxpeg==' target='_blank'>
             <FaInstagramSquare size={35} />
          </a>
          
        </div>
      </footer>

      <MenuMobile open={openMenu} close={() => setOpenMenu(!openMenu)} />

      <ModalForm open={openModal} close={() => setOpenModal(!openModal)} />

    </main>

    </>
  );
}

export default App;
