import React from "react";

import { Link, animateScroll as scroll } from "react-scroll";


export const MenuMobile = ({open, close}) => {
  return (
    <>
    { open &&
    <aside className="fixed top-16 left-0 z-40 w-[400px] h-full sm:translate-x-0 overflow-auto">
        <div className="h-full px-3 py-4 overflow-y-auto bg-gray-50">
            {/*
            <div className="flex items-center justify-center">
                <img src={logo} width={150} height={150} />
  </div>*/}
            <ul className="space-y-10 mt-7 font-medium justify-center items-center flex flex-col">
                <li>
                    <Link
                        activeClass="active"
                        to="depositions"
                        spy={true}
                        smooth={true}
                        offset={-70}
                        duration={500}
                        onClick={close}
                    >
                        <p className="text-xl text-[#d07ea7] hover:text-[#696969] cursor-pointer font-karla font-normal">
                            Depoimentos
                        </p>
                    </Link>
                </li>
                <li>
                    <Link
                        activeClass="active"
                        to="quemSomos"
                        spy={true}
                        smooth={true}
                        offset={-70}
                        duration={500}
                        onClick={close}
                    >
                        <p className="text-xl text-[#d07ea7] hover:text-[#696969] cursor-pointer font-karla font-normal">
                            Quem Somos
                        </p>
                    </Link>
                </li>
                <li>
                    <Link
                        activeClass="active"
                        to="galeria"
                        spy={true}
                        smooth={true}
                        offset={-70}
                        duration={500}
                        onClick={close}
                    >
                        <p className="text-xl text-[#d07ea7] hover:text-[#696969] cursor-pointer font-karla font-normal">
                            Galeria
                        </p>
                    </Link>
                </li>
                <li>
                <Link
                    activeClass="active"
                    to="contato"
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={500}
                    onClick={close}
                >
                    <p className="text-xl text-[#d07ea7] hover:text-[#696969] cursor-pointer font-karla font-normal">
                        Contato
                    </p>
                </Link>
                </li>
            </ul>
        </div>
    </aside>
}
</>
  )
}
